// You can customize the template with the help of this file

//Api config options
const apiConfig = {
    api: {

      url:'https://abacies.bettertomorrow.green/wp-json/'
      // url:'http://localhost/abacies/wp-json/'
    },
    // token: {
    //   // url2: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MjlmNjUwMWVmNGY2MzJkNDJmNDE0ZWIiLCJpYXQiOjE2NTQ2MTMyNjF9.JprEIgfAqT0wuLkcXxVqZ22TMmCz1s03ex2VlrnqFRw' // old token
    //   url2: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MmUwMGVhYmJhOGM3MjZlNzVlZWViMjQiLCJpYXQiOjE2NTg4NTA5OTV9.FU7DokaGISt6pq9Vh7ZMcJP3Ka6Cm85H0uXZyKsF1F8'
    // },
    // renderTime: {
    //   time1: env.REACT_APP_RENDER_TIME
    // }
  }
  
  export default apiConfig
  